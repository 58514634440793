/* ProblemUploadIndicator.css */

.loading-indicator {
    position: fixed;
    top: 10px;
    right: 10px; /* Default to top-right */
    padding: 10px 20px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    z-index: 1050;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
}

/* Adjust position for screens 1200px wide or less */
@media (max-width: 1200px) {
    .loading-indicator {
        right: auto; /* Remove right positioning */
        left: 10px; /* Position to the left */
    }
}

.loading-icon {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    border: 2px solid #f3f3f3;
    border-top-color: #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
