/* index.css */
/* Base styles for the entire document */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Styles for <code> elements to display code snippets */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 16px; 
  line-height: 1.5; 
  color: #1d1d1f; 
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  color: #1d1d1f;
}

h1 {
  font-size: 2em; /* Apple uses large titles, especially on marketing pages */
}

a {
  color: #06c;
  text-decoration: none;
  transition: color 0.2s;
}

a:hover {
  color: #005599;
}


/* Buttons and inputs */
button,
input[type="submit"],
input[type="button"] {
  background-color: #0071e3;
  border: none;
  border-radius: 18px; /* Apple-style pill buttons */
  color: white;
  padding: 1em 2em;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.2s;
}

button:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
  background-color: #0051a3;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  border: 1px solid #ccc;
  padding: 0.75em 1em;
  border-radius: 5px; /* Slightly rounded edges for inputs */
}
