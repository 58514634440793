/* QuicklyUploadQuestions.css */

.overlay {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
    background-color: #fefefe;
    margin: 2vh auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    max-width: 800px;
    height: 80vh;
    box-sizing: border-box;
    position: relative;
    overflow: auto;
    border-radius: 8px;
}

.close-button {
    color: #aaaaaa;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.2s;
}

.close-button:hover {
    color: #000;
}

.glow {
    animation: glow-animation 1.5s infinite;
}

@keyframes glow-animation {
    0% { box-shadow: 0 0 6px #4CAF50; }
    50% { box-shadow: 0 0 20px #4CAF50, 0 0 40px #4CAF50; }
    100% { box-shadow: 0 0 6px #4CAF50; }
}
