/* UploadImageQuestions.css */
.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.image-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.image-preview-wrapper {
    position: relative;
    display: inline-block;
}

.delete-button {
    position: absolute;
    top: 0;
    right: 0;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 2px 5px;
    margin: 2px;
}

.custom-label {
    display: inline-block;
    padding: 12px 20px;
    background: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
}

.hidden-input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin: 0;
    padding: 0;
}
