/* App.css */

.app {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}

.toggleButton {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 10;
    background-color: #007aff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}

.navigation {
    background-color: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    height: 100%;
    border-right: 1px solid #e1e1e6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 5;
    padding: 20px;
}

.mainContent {
    flex-grow: 1;
    transition: margin-left 0.3s ease;
    overflow-y: auto;
    position: relative; /* Ensures it covers the remaining space */
    margin-top: 3vw;
    margin-right: 0vw;
    margin-bottom: 0px;
    margin-left: 0vw; /* Adjust left margin dynamically */
}

@media (min-width: 1200px) {
.mainContent {
    flex-grow: 1;
    transition: margin-left 0.3s ease;
    overflow-y: auto;
    position: relative; /* Ensures it covers the remaining space */
    margin-top: 3vw;
    margin-right: 5vw;
    margin-bottom: 0px;
    margin-left: 3vw; /* Adjust left margin dynamically */
}
}