/* Global component class */
.latexNotationLegend {
  display: block;  /* Maintains block-level display */
  max-height: 400px;  /* Example max-height, adjust as needed */
  overflow: auto; /* Adds scroll if content exceeds max-height */
}

.latexNotationLegendHelpButton {
  border: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
  font-size: 24px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.latexNotationLegendHelpButton:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Subtle background change on hover */
}


/* Additional CSS for hiding the button */
.latexNotationLegendHelpButton.hidden {
  display: none;
}

.latexNotationLegendModalBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.latexNotationLegendModalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  max-height: 90vh;  /* limits the height to 90% of the viewport height */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  overflow-y: auto;  /* allows scrolling within the modal if content is taller */
  position: relative;  /* Ensures positioning is relative to the flex container */
}


.latexNotationLegendCloseButton {
  background-color: #ccc;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 10px; /* Moved from bottom to top */
  right: 10px; /* Adjusted right margin for consistency */
  transition: background-color 0.3s; /* Smooth transition for background color */
}
.latexNotationLegendCloseButton:hover {
  background-color: #bbb;
}

.spacer {
  height: 20px; /* Or any other height as per your design needs */
}

