/* Navigation.css */

.navigation {
  width: max(15%, 200px); /* Sets the width to whichever is maximum: 15% of the viewport width or 150px */
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  height: 100vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
}

.navigation h2 {
  margin: 20px 0 10px;
  font-size: 16px;
  font-weight: normal;
  color: #333;
}

.navigation button {
  padding: 10px 15px;
  margin-bottom: 8px;
  background-color: #f7f7f7;
  color: #333;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}

.navigation button:hover {
  background-color: #e0e0e0;
  box-shadow: 0 2px 3px rgba(0,0,0,0.1);
}

.navigation button:active {
  background-color: #d0d0d0;
}

.navigation ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.navigation li {
  width: 100%;
  margin-bottom: 5px;
}

.navigation li button {
  text-align: left;
}
